export const headers = [
  {
    text: 'Role Name',
    alignment: 'center',
    sortable: false,
    key: 'roles'
  },
  {
    text: 'Permissions',
    alignment: 'center',
    sortable: false,
    key: 'permissions'
  },
  {
    text: 'Role Type',
    alignment: 'center',
    sortable: false,
    key: 'type'
  },
  {
    text: 'No. of Permissions',
    alignment: 'center',
    sortable: false,
    key: 'count'
  },
  {
    text: 'Status',
    alignment: 'center',
    sortable: false,
    key: 'status'
  },
  {
    text: 'Date Created',
    alignment: 'center',
    sortable: false,
    key: 'date'
  },
  {
    text: '',
    alignment: 'center',
    sortable: false,
    key: 'action'
  }
];

export const data = [
  {
    roles: 'MDaas HQ',
    permissions: ['View', 'Edit'],
    count: 4,
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    roles: 'Financial Officer',
    permissions: ['View', 'Edit'],
    count: 4,
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    roles: 'Staff',
    permissions: ['View', 'Edit'],
    count: 4,
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    roles: 'MDaas HQ',
    permissions: ['View', 'Edit'],
    count: 4,
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    roles: 'MDaas HQ',
    permissions: ['View', 'Edit'],
    count: 4,
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    roles: 'MDaas HQ',
    permissions: ['View', 'Edit'],
    count: 4,
    status: 'active',
    date: 'May 27, 2022'
  },
  {
    roles: 'MDaas HQ',
    permissions: ['View', 'Edit'],
    count: 4,
    status: 'active',
    date: 'May 27, 2022'
  }
];

export const partnerInvoice = [
  {
    text: 'Patient Name',
    alignment: 'fullname',
    sortable: false,
    key: 'fullname'
  },
  {
    text: 'Center',
    alignment: 'room',
    sortable: false,
    key: 'center'
  },
  {
    text: 'Package',
    alignment: 'room',
    sortable: false,
    key: 'package'
  },
  {
    text: 'Procedure',
    alignment: 'procedure',
    sortable: false,
    key: 'procedure'
  },
  {
    text: 'Date',
    alignment: 'procedure',
    sortable: false,
    key: 'date'
  },

  {
    text: 'Amount',
    alignment: 'amount',
    sortable: false,
    key: 'amount'
  }
];
