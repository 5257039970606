import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './auth';
import { profileReducer } from './users';
import { roleReducers } from './roles';
import { centersReducer } from './centers';
import { procedureReducers } from './procedure';
import { packageReducers } from './packages';
import { auditReducers } from './audit';
import reportReducers from './report/report.reducer';
import financialReportReducers from './financialReport/financial.reducer';
import { dashboardReducers } from './dashboard';
import { supportReducers } from './support';
import { notificationReducer } from './notification';
import { resultsReducers } from './resultTracking';
import { claimReducers } from './claims';
import { rebateReducers } from './rebate';
import { onlineBookingsReducers } from './onlineBooking';
import { invoicesReducer } from './invoices';

const appReducers = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  role: roleReducers,
  centers: centersReducer,
  procedure: procedureReducers,
  packages: packageReducers,
  audits: auditReducers,
  reports: reportReducers,
  financialReport: financialReportReducers,
  dashboard: dashboardReducers,
  support: supportReducers,
  notifications: notificationReducer,
  claims: claimReducers,
  results: resultsReducers,
  rebates: rebateReducers,
  bookings: onlineBookingsReducers,
  invoices: invoicesReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT') {
    storage.removeItem('persist:root');

    return appReducers(undefined, action);
  }

  return appReducers(state, action);
};
