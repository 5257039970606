import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Button from 'components/Button/Button';
import Beacon from 'assets/icons/beacon-logo-hd.svg';
import styles from './index.module.scss';
import Address from 'assets/icons/address-gray.svg';
import Phone from 'assets/icons/phone-gray.svg';
import Close from 'assets/icons/close.svg';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { partnerInvoice } from 'mocks/roles';
import Table from 'components/Table';
import priceSplitter from 'utils/priceSplitter';
import { getProfileAction } from 're-ducks/users';
import Modal from 'components/Modal/Modal';
import FormatDate from 'helpers/formatDate';

export const Invoice = ({
  setOpenInvoice,
  selectedData,
  partnerInformation,
  partnersInvoiceTab,
  openInvoice,
  handlePartnerPatientDetails,
  setToggleAllCheck,
  setAllPatients,
  setSelectAll,
  viewType
}) => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const componentRef = useRef();

  useEffect(() => {
    dispatch(
      getProfileAction(
        (res) => {
          setData(res);
        },
        () => {}
      )
    );
  }, []);

  const DocumentTitle = partnersInvoiceTab
    ? `${selectedData?.invoice_id} ${partnerInformation?.name}`
    : `${selectedData?.invoice_number} ${partnerInformation?.name}`;
  const amounts = selectedData?.fields?.map((i) => i.total_amount);
  const totalInvoiceAmount = amounts?.reduce((accumulator, value) => {
    return Number(accumulator) + Number(value);
  });

  const tableHeaders =
    viewType === 'short'
      ? partnerInvoice.filter((header) => header.key !== 'package' && header.key !== 'procedure')
      : partnerInvoice;

  return (
    <Modal isOpen={openInvoice} width={1000} height={700}>
      <div>
        <div>
          <div ref={componentRef}>
            <div className={styles.invoice}>
              <img src={Beacon} />
              <h1 className={styles.invoice__heading}>INVOICE</h1>
              <div className={styles.invoice__close}>
                <img
                  src={Close}
                  alt="close"
                  onClick={() => {
                    setOpenInvoice(false);
                    handlePartnerPatientDetails && handlePartnerPatientDetails();
                    setToggleAllCheck && setToggleAllCheck(false);
                    setAllPatients && setAllPatients([]);
                    setSelectAll && setSelectAll(false);
                  }}
                  aria-hidden={true}
                />
              </div>{' '}
            </div>
            <div className={styles.invoice__header}>
              <div>
                <div className={styles.invoice__header_item}>
                  <img src={Address} alt="address" />
                  <div className={styles.invoice__header_text}>
                    <p>BeaconHealth Diagnostics</p>
                    <p>70 Fatai Idowu Arobieke, Lekki Phase 1.</p>
                    <p> {data?.email || '-'}</p>
                  </div>
                </div>
                <div className={styles.invoice__header_item1}>
                  <img src={Phone} alt="phone" />
                  <div className={styles.invoice__header_text}>
                    <p>{data?.phone_number || '-'}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.invoice__header_item}>
                  <div className={styles.invoice__header_text1}>
                    <div className={styles.invoice__header_span}>
                      <p> TO:</p> {partnerInformation?.name || '-'}
                    </div>
                    <p>{partnerInformation?.office_address || '-'}</p>
                    <div className={styles.invoice__header_span}>
                      <span>Date:</span> {moment.parseZone(new Date())?.format('DD MMMM YYYY')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.invoice__header_invoiceNo}>
              <div>{selectedData?.invoice_number}</div>
            </div>
            <Table
              headers={tableHeaders}
              tableData={selectedData?.fields}
              placeholderText="partner"
              emptyState
              placeHolderCaption="No patient yet">
              {(row) => (
                <>
                  <td style={{ width: '20%' }}>
                    {row.first_name} {row.last_name}
                  </td>
                  <td>{row.center || '-'}</td>
                  {viewType === 'long' && (
                    <>
                      <td>{row.package_name || '-'}</td>
                      <td style={{ wordBreak: 'break-word' }}>
                        {' '}
                        {row?.procedures?.map((item, index) => (
                          <span style={{ paddingBottom: '15px', textAlign: 'justify' }} key={index}>
                            {item},
                          </span>
                        ))}
                      </td>
                    </>
                  )}
                  <td>{FormatDate(row.visit_date) || '-'}</td>
                  <td style={{ width: '15%' }}>
                    ₦ {new Intl.NumberFormat().format(row?.total_amount)}
                  </td>
                </>
              )}
            </Table>
            <div className={styles.invoice__amount}>
              <div></div>

              <div>
                <div className={styles.invoice__amount_box}>
                  <p>Total amount </p>
                  <span>₦ {priceSplitter(Number(totalInvoiceAmount))}</span>
                </div>
              </div>
            </div>
            <div>
              <h2
                style={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#425466',
                  marginBottom: '12px'
                }}>
                PAYABLE TO:
              </h2>
              <div className={styles.invoice__header_item1}>
                {/* <p style={{ margin: "unset", fontWeight: 600 }}>Bank Name:</p> */}
                <div className={styles.invoice__header_text}>
                  <p style={{ margin: 'unset', fontWeight: 600 }}> First Bank of Nigeria</p>
                </div>
              </div>
              <div className={styles.invoice__header_item1}>
                <p style={{ margin: 'unset', fontWeight: 600 }}>Account Number:</p>
                <div className={styles.invoice__header_text}>
                  <p style={{ margin: '5px 0 0 0', fontWeight: 600 }}> &nbsp; 2034555352</p>
                </div>
              </div>
              <div className={styles.invoice__header_item1}>
                <p style={{ margin: 'unset', fontWeight: 600 }}>Account Name:</p>
                <div className={styles.invoice__header_text}>
                  <p style={{ margin: '5px 0 5px 0', fontWeight: 600 }}>
                    {' '}
                    &nbsp; BeaconHealth Diagnostics
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.invoice__details__button}>
            <ReactToPrint
              trigger={() => <Button theme="orange">Download Invoice</Button>}
              content={() => componentRef.current}
              documentTitle={DocumentTitle}
              onAfterPrint={() => setSelectAll && setSelectAll(false)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

Invoice.propTypes = {
  setOpenInvoice: PropTypes.any,
  selectedData: PropTypes.array,
  partnerInformation: PropTypes.object,
  partnersInvoiceTab: PropTypes.bool,
  openInvoice: PropTypes.any,
  handlePartnerPatientDetails: PropTypes.func,
  setToggleAllCheck: PropTypes.func,
  setAllPatients: PropTypes.func,
  setSelectAll: PropTypes.func,
  viewType: PropTypes.any
};
