import RightSideModal from 'components/Modal/RightSideModal';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePickerInput from 'components/FormInput/DatePickerInput';
import styles from './invoices.module.scss';
import Button from 'components/Button/Button';
import moment from 'moment';
import RadioButton from 'components/RadioButton';
import { useDispatch } from 'react-redux';
import { getBranchesAction } from 're-ducks/centers';
import { getAllPartnersAction } from 're-ducks/partner';
import ReactSelect from 'components/Select/ReactSelect';

const InvoicesFilter = ({ isOpen, isClose, setPage, setFilteredData }) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDateCreated, setStartDateCreated] = useState('');
  const [endDateCreated, setEndDateCreated] = useState('');
  const [status, setStatus] = useState('');
  const [centerName, setCenterName] = useState([]);
  const [partnerName, setPartnerName] = useState([]);

  const centerNameOption = centerName?.map((item) => ({
    label: item?.name,
    value: item?.name
  }));
  const partnerOption = partnerName?.map((item) => ({
    label: item?.name,
    value: item?.name
  }));
  const [selectCenterName, setSelectCenterName] = useState({
    id: '',
    name: ''
  });
  const [selectPartnerName, setSelectPartnerName] = useState({
    id: '',
    name: ''
  });

  useEffect(() => {
    dispatch(
      getBranchesAction(
        {},
        (res) => {
          setCenterName(res?.branches);
        },
        () => {}
      )
    );
  }, []);
  useEffect(() => {
    dispatch(
      getAllPartnersAction(
        {},
        (res) => {
          setPartnerName(res?.partners);
        },
        () => {}
      )
    );
  }, []);

  const handleFilter = () => {
    setFilteredData({
      organization: selectPartnerName ? selectPartnerName?.value : '',
      center: selectCenterName ? selectCenterName?.value : '',
      status: status ? status : '',
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      start_date_created: startDateCreated ? moment(startDateCreated).format('YYYY-MM-DD') : '',
      end_date_created: endDateCreated ? moment(endDateCreated).format('YYYY-MM-DD') : ''
    });
    isClose();
    setPage(1);
  };

  return (
    <RightSideModal isOpen={isOpen} isClose={isClose}>
      <div>
        <div className={styles.invoicesFilter_header}>
          <h2>Filter</h2>
          <button
            className={styles.invoicesFilter_header_cancelButton}
            onClick={() => {
              isClose();
              setPage(1);
            }}>
            X
          </button>{' '}
        </div>
        <div>
          <p className={styles.invoicesFilter_title}>Date generated</p>
          <div className={styles.invoicesFilter_dateRange}>
            <DatePickerInput
              selected={startDateCreated}
              selectsStart
              startDate={startDateCreated}
              endDate={endDateCreated}
              onChange={(date) => setStartDateCreated(date)}
              placeholderText="dd mm yyyy"
            />
            <DatePickerInput
              selected={endDateCreated}
              selectsEnd
              startDate={startDateCreated}
              endDate={endDateCreated}
              minDate={startDateCreated}
              onChange={(date) => setEndDateCreated(date)}
              placeholderText="dd mm yyyy"
            />
          </div>

          <div>
            <p className={styles.invoicesFilter_title}>Date paid</p>
            <div className={styles.invoicesFilter_dateRange}>
              <DatePickerInput
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="dd mm yyyy"
              />
              <DatePickerInput
                selected={endDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="dd mm yyyy"
              />
            </div>
          </div>
          <div style={{ marginTop: '25px' }}>
            <p className={styles.invoicesFilter_selectTitle}>Source(Centre)</p>
            <ReactSelect
              options={centerNameOption}
              placeholder="Select centre"
              onChange={(e) => setSelectCenterName(e)}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <p className={styles.invoicesFilter_title}>Visit status</p>
            <RadioButton
              text="Paid"
              value="paid"
              name="status"
              onChange={(e) => setStatus(e.target.value)}
            />

            <RadioButton
              text="Not paid"
              value="not_paid"
              name="status"
              onChange={(e) => setStatus(e.target.value)}
            />
          </div>
          <div style={{ marginTop: '25px' }}>
            <p className={styles.invoicesFilter_selectTitle}>Organisation</p>
            <ReactSelect
              options={partnerOption}
              placeholder="Select organisation"
              onChange={(e) => setSelectPartnerName(e)}
            />
          </div>
        </div>
        <div className={styles.invoicesFilter_button}>
          <Button size="sm" type="submit" theme="oblige" onClick={() => isClose()}>
            Cancel
          </Button>
          <Button size="sm" type="submit" theme="primary" onClick={() => handleFilter()}>
            Filter
          </Button>
        </div>
      </div>
    </RightSideModal>
  );
};

InvoicesFilter.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.object,
  setFilteredData: PropTypes.any,
  setPage: PropTypes.object
};
export default InvoicesFilter;
