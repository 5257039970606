/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormInput from 'components/FormInput/FormInput';
import Select from 'components/Select';
import Close from 'assets/icons/close.svg';
import styles from './partner.module.scss';
import Button from 'components/Button/Button';
import { useDispatch } from 'react-redux';
import Modal from 'components/Modal/Modal';
import { toast } from 'react-toastify';
import priceSplitter from 'utils/priceSplitter';
import {
  createPartnerPaymentAction,
  getPartnerInvoicesAction
} from 're-ducks/partner/partners.action';
import { useParams } from 'react-router-dom';

const MakePayment = ({
  setOpenPayment,
  openPayment,
  source,
  selectedData,
  handlePartnerPatientDetails,
  setSelectedItems,
  setSelectAll,
  partnerInvoice,
  data,
  setAllInvoices,
  setLoading
}) => {
  const [load, setLoader] = useState(false);
  const [payAmount, setPayAmount] = useState('');
  const [paymentMode, setPayentMode] = useState({
    name: ''
  });
  const params = useParams();
  const dispatch = useDispatch();
  const paymentTypes = [
    {
      id: 1,
      name: 'Cash'
    },
    {
      id: 2,
      name: 'Transfer'
    },
    { id: 3, name: 'POS' }
  ];

  const amounts = selectedData?.map((i) => i?.total_amount);
  const totalInvoiceAmount = amounts?.reduce((accumulator, value) => {
    return Number(accumulator) + Number(value);
  });
  useEffect(() => {
    setPayAmount(partnerInvoice ? Number(data?.amount_remaining ): Number(totalInvoiceAmount));
  }, []);

  const handlePayment = () => {
    setLoader(true);
    dispatch(
      createPartnerPaymentAction(
        {
          visit_ids: partnerInvoice ? data?.not_paid_visits : selectedData?.map((i) => i.visit_id),
          total_amount_paid: payAmount,
          payment_method: paymentMode?.name
        },
        () => {
          setLoader(false);
          toast.success('Partners payment created successfully');
          setOpenPayment(false);
          handlePartnerPatientDetails();
          !setSelectAll(false);
          setSelectedItems([]);
          if (partnerInvoice) {
            setLoading(true);
            dispatch(
              getPartnerInvoicesAction(
                {
                  id: params.id,
                  params: {
                    perPage: 50,
                    page: 1
                  }
                },
                (res) => {
                  setAllInvoices(res);
                  setLoading(false);
                },
                (err) => {
                  toast.error(err);
                  setLoading(false);
                }
              )
            );
          }
        },
        (err) => {
          setLoader(false);
          toast.error(err);
          setSelectAll(false);
          setOpenPayment(false);
        }
      )
    );
  };

  


  return (
    <Modal isOpen={openPayment} width="450" height="600">
      <div className={styles.payment__modal}>
        <div className={styles.payment__modal__header}>
          <h1>Make payment</h1>
          <img src={Close} onClick={() => setOpenPayment(false)} />
        </div>

        <form>
          <div className={styles.payment__form}>
            <div className={styles.payment__form__item}>
              <Select
                label="Select payment mode"
                placeholder="Select"
                options={paymentTypes?.map((item) => ({
                  name: item.name,
                  code: item.id
                }))}
                selected={paymentMode}
                handleChange={setPayentMode}
              />
            </div>
            <br />
            <div className={styles.payment__form__item}>
              <FormInput
                value={source}
                label="Paying Source"
                placeholder="Paying Source"
                disabled
              />
            </div>
            <br />
            <div className={styles.payment__form__item}>
              <FormInput
                defaultValue={
                  partnerInvoice
                    ? `₦ ${priceSplitter(Number(data?.amount_remaining || data?.amount))}`
                    : `₦ ${priceSplitter(Number(totalInvoiceAmount))}`
                }
                label="Amount To Pay"
                placeholder="Amount to pay"
                disabled
              />
            </div>
            <br />
            <div className={styles.payment__form__item}>
              <FormInput
                label="Amount paid"
                placeholder="Amount paid"
                type="number"
                defaultValue={partnerInvoice ? data?.amount_remaining || data?.amount : totalInvoiceAmount}
                onChange={(e) => setPayAmount(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.payment__btn}>
            <Button
              theme="orange"
              loading={load}
              type="button"
              onClick={() => handlePayment()}
              disabled={
                paymentMode?.name?.length === 0 ||
                Number(payAmount) > Number(totalInvoiceAmount) ||
                payAmount?.length === 0
              }
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MakePayment;

MakePayment.propTypes = {
  source: PropTypes.string,
  selectedData: PropTypes.array,
  selectedVistIDs: PropTypes.selectedVistIDs,
  setOpenPayment: PropTypes.any,
  handlePartnerPatientDetails: PropTypes.func,
  setSelectedItems: PropTypes.any,
  setSelectAll: PropTypes.func
};
