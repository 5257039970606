import DashboardLayout from 'container/layouts/dashboard';
import Invoices from 'container/invoices/invoices';


const InvoicesPage = () => {
  return (
    <DashboardLayout>
      <Invoices/>
    </DashboardLayout>
  );
};

export default InvoicesPage;
