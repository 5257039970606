import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const InvoicesRow = ({ children, row, page, filteredData }) => {

  return (
    <td
      row={row}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </td>
  );
};

InvoicesRow.propTypes = {
  children: PropTypes.any,
  row: PropTypes.object,
  page: PropTypes.number,
  filteredData: PropTypes.any
};

export default InvoicesRow;
