import React from 'react';
import moment from 'moment';

export const FormatDateTime = (date) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {moment.parseZone(date)?.format('MMMM DD, YYYY')}|{' '}
      <span style={{ color: '#81909D', textTransform: 'uppercase' }}>
        {moment.parseZone(date)?.format('h:ss a')}
      </span>
    </div>
  );
};

export const FormatDateAndTime = (date) => {
  return (
    <div style={{ display: 'flex', gap: '2px', flexWrap: 'wrap' }}>
      {moment.parseZone(date)?.format(' DD MMM, YYYY')} |{' '}
      <span style={{ color: '#81909D', textTransform: 'uppercase' }}>
        {moment.parseZone(date)?.format('hh:ss a')}
      </span>
    </div>
  );
};
export const FormatDate = (date) => {
  return <div>{moment.parseZone(date)?.format(' DD MMM, YYYY')} </div>;
};
