import React from 'react';
import moment from 'moment';

const FormatDate = (date) => {
  return <div>{moment.parseZone(date)?.format('MMMM DD, YYYY')}</div>;
};
export default FormatDate;

export const FormatDateShort = (date) => {
  return <div>{moment.parseZone(date)?.format('MMM DD, YYYY')}</div>;
};

export const FormatDateOfBirth = (date) => {
  return <div>{moment.parseZone(date)?.format('DD/MM/yyyy')}</div>;
};
