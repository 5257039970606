import React, {lazy} from 'react';
import InvoicesPage from 'pages/Invoices';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';

export default function InvoicesRoutes() {
  return (
    <Routes>
      <Route
        path="/invoices"
        element={
          <PrivateRoute>
            <InvoicesPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
