import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Avatar from '../Avatar';
import ArrowDown from 'assets/icons/arrow_down.svg';
import styles from './header.module.scss';
import { useLocation } from 'react-router-dom';
import { handleUpdateName } from 'helpers/nameChange';
import Dropdown from '../Dropdown/Dropdown';
import Profile from 'assets/icons/profile.svg';
import LogOut from 'assets/icons/logout.svg';
import { Option } from '../Dropdown/Option';
import LogOutModal from './LogOut';
import Notifications from 'container/notifications/notifications';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openModal, setModal] = useState(false);
  const data = useSelector((state) => state?.profile?.profile);
  const full_name = `${data?.first_name} ${data?.last_name}`;

  const headerTitle = () => {
    switch (location?.pathname) {
      case '/dashboard':
        return 'Dashboard';
      case '/centers':
        return 'Centers';
      case '/admin-management':
        return 'User Management';
      case '/audit/trail':
        return 'Audit Trail';
      case '/procedure':
        return 'Rooms';
      case '/partners':
        return 'Partners';
      case '/patients':
        return 'Patients';
      case '/referrals':
        return 'Referrals';
      case '/reports':
        return 'Reports';
      case '/role-management':
        return 'Role Management';
      case '/support':
        return 'Support';
      case '/result-tracking':
        return 'Result Tracking';
      case '/rebate':
        return 'Rebates';
      case '/packages':
        return 'Packages';
      case '/financial-report':
        return 'Financial Reporting';
      case '/invoices':
        return 'Invoices';
      case '/notifications':
        return 'Notification';
      case '/claims':
        return 'Claims';
      case '/online-bookings':
        return 'Bookings';
      case '/website-management':
        return 'Website Management';
      default:
        return '';
    }
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.header__group}>
          <div className={styles.header__notification}>
            <Notifications />
          </div>
          <div className={styles.admin}>
            <p className={styles.admin__name}>{full_name}</p>
            <p className={styles.admin__role}>{handleUpdateName(data?.role)}</p>
          </div>
          <Avatar name={full_name} url={data?.profile_image} />
          <div className={styles.admin__details__img}>
            <Dropdown
              content={
                <>
                  <Option image={Profile} onClick={() => navigate('/settings')}>
                    <p>Profile</p>
                    <p className={styles.drop_caption}>Update your profile</p>
                  </Option>
                  <Option image={LogOut} onClick={() => setModal(true)}>
                    <p>Log out</p>
                    <p className={styles.drop_caption}>Logout of your account</p>
                  </Option>
                </>
              }
            >
              <img src={ArrowDown} alt="arrow" />
            </Dropdown>
          </div>
        </div>
      </header>
      <div>
        <h2 className={styles.header__title}>{headerTitle()}</h2>
      </div>
      {openModal && <LogOutModal isClose={() => setModal(false)} isOpen={true} />}
    </>
  );
};

export default Header;
