import HomeIcon from 'assets/icons/homeIcon.svg';
import CenterIcon from 'assets/icons/centersIcon.svg';
import PatientIcon from 'assets/icons/patientIcon.svg';
import PartnersIcon from 'assets/icons/partnersIcon.svg';
import ReportIcon from 'assets/icons/reportIcon.svg';
import AdminIcon from 'assets/icons/adminIcon.svg';
import RoleIcon from 'assets/icons/roleIcon.svg';
import PlanIcon from 'assets/icons/plansIcon.svg';
import ProcedureIcon from 'assets/icons/procedure.svg';
import ReferralIcon from 'assets/icons/referralsIcon.svg';
import AuditIcon from 'assets/icons/auditIcon.svg';
import SupportIcon from 'assets/icons/supportIcon.svg';
import bookingIcon from 'assets/icons/bookingIcon.svg';

const navLinks = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    name: 'homeIcon',
    image: HomeIcon,
    key: 'dashboard'
  },
  {
    title: 'Centers',
    url: '/centers',
    name: 'centersIcon',
    image: CenterIcon,
    key: 'center'
  },
  {
    title: 'Patient',
    url: '/patients',
    name: 'patientIcon',
    image: PatientIcon,
    key: 'patient'
  },
  {
    title: 'Health Providers',
    url: '/referrals',
    name: 'referralsIcon',
    image: ReferralIcon,
    key: 'referral'
  },
  {
    title: 'Partners',
    url: '/partners',
    name: 'partnersIcon',
    image: PartnersIcon,
    key: 'partner'
  },
  {
    title: 'Test Menu',
    url: '/procedure',
    name: 'procedureIcon',
    image: ProcedureIcon,
    key: 'procedure'
  },
  {
    title: 'Clinical Report',
    url: '/reports',
    name: 'reportIcon',
    image: ReportIcon,
    key: 'result'
  },
  {
    title: 'Claims',
    url: '/claims',
    name: 'claimsIcon',
    image: ReportIcon,
    key: 'claims'
  },
  {
    title: 'Packages',
    url: '/packages',
    name: 'plansIcon',
    image: PlanIcon,
    key: 'package'
  },
  {
    title: 'Bookings',
    url: '/online-bookings',
    name: 'bookingIcon',
    image: bookingIcon,
    key: 'dashboard'
  },
  {
    title: 'Result Tracking',
    url: '/result-tracking',
    name: 'resultIcon',
    image: AdminIcon,
    key: 'result tracking'
  },
  {
    title: 'Rebate management',
    url: '/rebate',
    image: ReferralIcon,
    key: 'rebate',
    name: ''
  },
  {
    title: 'Financial Report',
    url: '/financial-report',
    name: 'financialIcon',
    image: ReportIcon,
    key: 'financial report'
  },
  {
    title: 'Invoices',
    url: '/invoices',
    name: 'invoicesIcon',
    image: ReportIcon,
    key: 'invoice'
  },
  {
    title: 'User Management',
    url: '/admin-management',
    name: 'adminIcon',
    image: AdminIcon,
    key: 'user'
  },
  {
    title: 'Role Management',
    url: '/role-management',
    name: 'roleIcon',
    image: RoleIcon,
    key: 'role'
  },
  {
    title: 'Website Management',
    url: '/website-management',
    name: 'homeIcon',
    image: HomeIcon,
    key: 'dashboard'
  },
  {
    title: 'Support',
    url: '/support',
    name: 'supportIcon',
    image: SupportIcon,
    key: 'support'
  },
  {
    title: 'Audit Trail',
    url: '/audit/trail',
    name: 'auditIcon',
    image: AuditIcon,
    key: 'audit'
  }
];

export default navLinks;
