import userHasPermission from 'helpers/userHasPermissions';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const useActivePermission = (permissionKey, permissionType) => {
  const auth = useSelector((state) => state.auth);
  
  return useMemo(() => {
    return (
      userHasPermission(auth?.signIn?.userPermissions, permissionKey, permissionType) ||
      auth?.signIn?.role?.toLowerCase() === 'hq_admin'
    );
  }, [auth?.signIn?.userPermissions, auth?.signIn?.role, permissionKey, permissionType]);
};

export default useActivePermission;