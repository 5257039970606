export default {
  // auth
  SIGNIN: '/auth/login',
  FORGOT: '/auth/forgot-password',
  VALIDATE_OTP: '/auth/validate-otp',
  SET_PASSWORD: '/auth/reset-password',
  SET_NEW_PASSWORD: '/auth/set-password',
  REFRESH_TOKEN: '/auth/refresh-token',

  // profile
  USER_PROFILE: 'user/profile',
  USER: '/user',
  UPDATE_USER_PASSWORD: '/auth/change-password',

  //role management
  ROLE: '/role',

  // centers
  BRANCH: '/branch',

  //procedure
  FETCH_PROCEDURE: '/procedure',

  //packages
  FETCH_PACKAGE: '/package',

  // partners
  PARTNERSHIP_TYPE: '/partners/partnership-type',
  PARTNERS: '/partners',

  //audits
  FETCH_AUDITS: '/settings/audit',

  // patient
  PATIENT: '/patient',

  // referral
  REFEREE: 'referee',

  // referral
  SETTING: '/settings',

  //analytics
  ANALYTICS: '/analytics',

  //Support
  SUPPORT: '/support',

  //Claims
  CLAIMS: '/claims',

  //SuperPackages
  SUPERPACKAGES: '/super-package',

  // online booking
  ONLINEBOOKING: 'web/visit-enquiry-list',
  ONLINEBOOKINGDETAILS: 'web/visit-enquiry',
  HOMECOLLECTIONLOGISTICS: '/web/all-logistics',

  //WebsiteCenters
  WEBSITECENTERS: '/web/all-centers',

  //WebsiteCenters
  CREATEWEBSITECENTERS: 'web/centers',
  CENTER: '/web',

  WEBCENTER: 'web/center',
  INVOICES:  'settings/all-invoices'
};
